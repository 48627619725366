import { isNil, isNotNil } from 'ramda'

import FilePdf from '~/src/components/generic/PhosphorIcons/FilePdfIcon'

import {
  DEFAULT_TIME_ZONE_NAME,
  DOCUMENT_TYPE_LABELS,
  LEG_COLORS,
  OPERATIONAL_STOP,
} from '~/src/components/shared/features/constants'
import { UserType } from '~/src/components/shared/features/types'

import {
  convertToTimezone,
  diffInSeconds,
  isDateBeforeComparison,
} from '~/helpers/date'
import { formatAddress } from '~/helpers/location'
import { endOfDay, startOfDay } from '~/helpers/time'
import { enumToPretty } from '~/helpers/string'

import {
  Document,
  DocumentExtension,
  DocumentType,
  GpsPoint,
  LoadStatus,
  ShipmentStatus,
  SortInput,
  StopLatenessStatus,
} from '~/__generated_types__/globalTypes'

// TODO: Add tests for all of these helper functions missing them:
// https://app.asana.com/0/1206283941353593/1206676201369455
const getOppositeDirection = (direction: string): SortInput => {
  if (direction === SortInput.None) {
    return SortInput.Asc
  } else if (direction === SortInput.Asc) {
    return SortInput.Desc
  }

  return SortInput.None
}

const capitalize = (str: string): string => {
  const lowerCaseString = str.toLowerCase()

  return lowerCaseString.charAt(0).toUpperCase() + lowerCaseString.slice(1)
}

const docCreatedAtComparator = (docA: Document, docB: Document): number => {
  if (docA.createdAt && docB.createdAt) {
    return Date.parse(docB.createdAt) - Date.parse(docA.createdAt)
  } else return 0
}

/**
 * Returns the best available document based on the following priority:
 * 1. If no documents are provided, returns null
 * 2. The most recent DocumentType.Mb type doc
 * 3. The most recent DocumentType.ProofOfDelivery doc, prioritizing docs with 'EPOD' in the name
 * 4. The most recent document of any DocumentType
 */
const getBestProofOfDeliveryDoc = (docs: Array<Document>): Document | null => {
  if (docs.length === 0) return null

  const mbDocs = docs.filter((doc) => doc.type === DocumentType.Mb)
  if (mbDocs.length > 0) {
    return mbDocs.sort(docCreatedAtComparator)[0]
  }

  const podDocs = docs.filter((doc) => doc.type === DocumentType.ProofOfDelivery)
  if (podDocs.length > 0) {
    podDocs.sort((docA, docB) => {
      const isEpodA = docA.name?.toUpperCase().includes('EPOD')
      const isEpodB = docB.name?.toUpperCase().includes('EPOD')

      if (isEpodA && !isEpodB) return -1
      if (!isEpodA && isEpodB) return 1

      return docCreatedAtComparator(docA, docB)
    })

    return podDocs[0]
  }

  return docs.sort(docCreatedAtComparator)[0]
}

const getDocumentTypeLabel = (docType: DocumentType) => {
  if (docType in DOCUMENT_TYPE_LABELS) {
    return DOCUMENT_TYPE_LABELS[docType]
  }

  return ''
}

const getDocumentFilterType = (docType?: DocumentType | null) => {
  if (
    docType === DocumentType.ProofOfDelivery ||
    docType === DocumentType.BillOfLading ||
    docType === DocumentType.Tender
  ) {
    return docType
  }

  return DocumentType.Other
}

const getDocumentIcon = (docExtension: DocumentExtension) => {
  switch (docExtension) {
    case DocumentExtension.Pdf:
      return FilePdf
  }
}

const getLocaleTimeString = (time: Date | string | undefined | null) => {
  const options: Intl.DateTimeFormatOptions = {
    hour: '2-digit',
    minute: '2-digit',
    hourCycle: 'h23',
  }

  if (time instanceof Date) {
    return time.toLocaleTimeString('en-US', options)
  }

  return time ? new Date(time).toLocaleTimeString('en-US', options) : undefined
}

const getLocaleDateString = (
  date: Date | string | undefined | null,
  option?: Intl.DateTimeFormatOptions
): string | undefined => {
  const defaultOption: Intl.DateTimeFormatOptions = {
    month: '2-digit',
    day: '2-digit',
  }

  if (date instanceof Date) {
    date.toLocaleDateString('en-US', option ?? defaultOption)
  }

  return date
    ? new Date(date).toLocaleDateString('en-US', option ?? defaultOption)
    : undefined
}

const getDwellTime = (
  arrivalTime: string | undefined | null,
  departureTime: string | undefined | null,
  estimatedArrivalTime: string | undefined | null,
  estimatedDepartureTime: string | undefined | null
): { dwellTime: number | undefined; isEstimate: boolean } => {
  let dwellTime: number | undefined = undefined
  let isEstimate = false

  if (arrivalTime && departureTime) {
    dwellTime = diffInSeconds(new Date(departureTime), new Date(arrivalTime))
  } else if (arrivalTime && estimatedDepartureTime) {
    dwellTime = diffInSeconds(new Date(estimatedDepartureTime), new Date(arrivalTime))
    isEstimate = true
  } else if (estimatedArrivalTime && estimatedDepartureTime) {
    dwellTime = diffInSeconds(
      new Date(estimatedDepartureTime),
      new Date(estimatedArrivalTime)
    )
    isEstimate = true
  }

  return { dwellTime, isEstimate }
}

const getLegTotalTime = (
  startTime: string | undefined | null,
  endTime: string | undefined | null,
  estimatedTripTime: number
): number => {
  if (endTime && startTime) {
    return diffInSeconds(new Date(endTime), new Date(startTime))
  }

  return estimatedTripTime
}

const getLocalTime = (
  time: string | undefined | null,
  timeZoneName: string | undefined
): string | undefined => {
  const localTime =
    time && convertToTimezone(time, timeZoneName ?? DEFAULT_TIME_ZONE_NAME)

  return localTime ?? undefined
}

/**
 * Helper that gets the ISO string representation of the current time minus 24 hours.
 *
 * @returns {string} The ISO string representation of the time 24 hours ago.
 */
const getNotificationTimeFloor = (): string => {
  return new Date(new Date().getTime() - 24 * 60 * 60 * 1000).toISOString()
}

const getLegColor = (legIdx: number) => LEG_COLORS[legIdx % LEG_COLORS.length].hexColor

const getSortingColumnName = (sortEnum: string): string => {
  const [...sortingColumnParts] = (sortEnum || '').split('_')

  return sortingColumnParts.slice(0, sortingColumnParts.length - 1).join('_')
}

/**
 * helper function to get the date range
 */
const getWeekDateRange = ({
  startOffset = 0,
  endOffset = 0,
}: {
  startOffset?: number
  endOffset?: number
}): {
  start: string
  end: string
} => {
  const today = new Date()

  const startDateTime = startOfDay(today)
  const endDateTime = endOfDay(today)
  const startDate = new Date(
    startDateTime.setDate(startDateTime.getDate() - startOffset)
  )
  const endDate = new Date(endDateTime.setDate(endDateTime.getDate() + endOffset))

  return { start: startDate.toISOString(), end: endDate.toISOString() }
}

const getLoadEmptyStateMessage = (isFilterEmpty: boolean) =>
  isFilterEmpty
    ? 'Look up a single load by typing in Ryder # or Reference # in a search bar, or view a list of loads by selecting cost centers and/or other filters.'
    : 'There are no loads based on your applied filters. Adjust your filters or search by reference number to view loads.'

const isOperationalStop = (locationName: string | null | undefined) => {
  return !locationName || locationName === OPERATIONAL_STOP
}

const formatOperationalStopAddress = (
  address:
    | {
        line1?: string | null
        line2?: string | null
        city?: string | null
        stateOrProvinceAbbrev?: string | null
        postalCode?: string | null
      }
    | null
    | undefined,
  point: GpsPoint | null | undefined
) => {
  if (!address && !point) return '\u2014'

  const formattedAddress = formatAddress(address)

  if (point && !address?.line1 && !address?.line2) {
    const latLon = `(${point.lat.toFixed(5)}, ${point.lon.toFixed(5)})`

    return `${formattedAddress} ${latLon}`
  }

  return formattedAddress
}

const isValidLatenessStatus = (
  status: ShipmentStatus | LoadStatus | null | undefined,
  arrivalTime: string | null | undefined,
  departureTime: string | null | undefined
): boolean => {
  if (isNil(status) || isNotNil(arrivalTime) || isNotNil(departureTime)) return false

  return [ShipmentStatus.PickedUp, LoadStatus.Started].includes(status)
}

/**
 * getStopLatenessDuration determines how long a stop is late and if so how late for a customer.
 * Return positive number if late, 0 or negative or null if not late.
 * When there's no lateness threshold time, return 0 by default.
 */
const getStopLatenessDurationCustomer = ({
  estimatedArrivalTime,
  latenessThresholdTime,
  status,
  arrivalTime,
  departureTime,
}: {
  estimatedArrivalTime: string | null | undefined
  latenessThresholdTime: string | null | undefined
  status: ShipmentStatus | LoadStatus | null | undefined
  arrivalTime: string | null | undefined
  departureTime: string | null | undefined
}) => {
  if (
    isNil(latenessThresholdTime) ||
    isNil(estimatedArrivalTime) ||
    !isValidLatenessStatus(status, arrivalTime, departureTime)
  )
    return 0

  const thresholdDate = new Date(latenessThresholdTime)
  const etaDate = new Date(estimatedArrivalTime)
  const dateNow = new Date()
  const maxNowOrEta = etaDate > dateNow ? etaDate : dateNow

  if (maxNowOrEta > thresholdDate) {
    return diffInSeconds(maxNowOrEta, thresholdDate)
  }

  return 0
}

/**
 * getStopLatenessDurationOperator determines how long a stop is late and if so how
 * late for an operator.
 * Return positive number if late, 0 if not late.
 */
const getStopLatenessDurationOperator = ({
  estimatedArrivalTime,
  latenessThresholdTime,
  status,
  arrivalTime,
  departureTime,
}: {
  estimatedArrivalTime: string | null | undefined
  latenessThresholdTime: string | null | undefined
  status: ShipmentStatus | LoadStatus | null | undefined
  arrivalTime: string | null | undefined
  departureTime: string | null | undefined
}): number => {
  if (!isValidLatenessStatus(status, arrivalTime, departureTime)) return 0

  const isThresholdTimePastETA =
    isNotNil(latenessThresholdTime) &&
    isNotNil(estimatedArrivalTime) &&
    isDateBeforeComparison(latenessThresholdTime, estimatedArrivalTime)
  const thresholdTimePastETA = isThresholdTimePastETA
    ? diffInSeconds(new Date(estimatedArrivalTime), new Date(latenessThresholdTime))
    : 0

  return thresholdTimePastETA
}

const isStopLate = ({
  arrivalTime,
  departureTime,
  estimatedArrivalTime,
  latenessThresholdTime,
  status,
  userType,
}: {
  arrivalTime: string | null | undefined
  departureTime: string | null | undefined
  estimatedArrivalTime: string | null | undefined
  latenessThresholdTime: string | null | undefined
  status: ShipmentStatus | LoadStatus | null | undefined
  userType: UserType
}): boolean => {
  const latenessDuration =
    userType === UserType.CUSTOMER
      ? getStopLatenessDurationCustomer({
          estimatedArrivalTime,
          latenessThresholdTime,
          status,
          arrivalTime,
          departureTime,
        })
      : getStopLatenessDurationOperator({
          estimatedArrivalTime,
          latenessThresholdTime,
          status,
          arrivalTime,
          departureTime,
        })

  return latenessDuration > 0
}

const prettyDuration = (durationSeconds: number): string => {
  if (durationSeconds < 0) return ''

  const days = Math.floor(durationSeconds / (24 * 60 * 60))
  const hours = Math.floor((durationSeconds % (24 * 60 * 60)) / (60 * 60))
  const minutes = Math.floor((durationSeconds % (60 * 60)) / 60)

  const parts = []
  if (days > 0) parts.push(`${days}d`)
  if (hours > 0) parts.push(`${hours}h`)
  if (minutes > 0) parts.push(`${minutes}m`)

  return parts.length > 0 ? `${parts.join(' ')}` : '0m'
}

const getFirstLateStopWithLabel = (
  stopInfo: Array<{
    latenessStatus: StopLatenessStatus | null | undefined
    appointmentEnd: string | null | undefined
    arrivalTime: string | null | undefined
  }>
): {
  latenessStatus: StopLatenessStatus | null | undefined
  label: string | undefined
} => {
  const firstLateStop = stopInfo.find(
    (stop) =>
      stop.latenessStatus === StopLatenessStatus.Late &&
      !isNil(stop.appointmentEnd) &&
      isNil(stop.arrivalTime)
  )

  if (isNotNil(firstLateStop)) {
    return {
      latenessStatus: StopLatenessStatus.Late,
      label: firstLateStop.appointmentEnd
        ? `${prettyDuration(
            diffInSeconds(new Date(), new Date(firstLateStop.appointmentEnd))
          )} late`
        : undefined,
    }
  }

  const firstRunningLateStop = stopInfo.find(
    (stop) => stop.latenessStatus === StopLatenessStatus.RunningLate
  )

  return {
    latenessStatus: firstRunningLateStop?.latenessStatus,
    label: firstRunningLateStop
      ? enumToPretty(StopLatenessStatus.RunningLate)
      : undefined,
  }
}

const getOperatorLatenessLabel = (
  currentTime: Date,
  latenessStatus: StopLatenessStatus | null | undefined,
  appointmentEnd: string | null | undefined,
  arrivalTime: string | null | undefined
): string | undefined => {
  if (isNil(latenessStatus) || isNil(appointmentEnd) || isNotNil(arrivalTime))
    return undefined

  if (latenessStatus === StopLatenessStatus.Late) {
    const timeDiff = diffInSeconds(currentTime, new Date(appointmentEnd))

    if (timeDiff < 0) return undefined

    return `${prettyDuration(timeDiff)} late`
  } else if (latenessStatus === StopLatenessStatus.RunningLate) {
    return enumToPretty(StopLatenessStatus.RunningLate)
  }
}

export {
  capitalize,
  formatOperationalStopAddress,
  getBestProofOfDeliveryDoc,
  getDocumentFilterType,
  getDocumentIcon,
  getDocumentTypeLabel,
  getDwellTime,
  getLegColor,
  getLegTotalTime,
  getLoadEmptyStateMessage,
  getLocalTime,
  getLocaleDateString,
  getLocaleTimeString,
  getNotificationTimeFloor,
  getOppositeDirection,
  getSortingColumnName,
  getStopLatenessDurationCustomer,
  getStopLatenessDurationOperator,
  getWeekDateRange,
  isOperationalStop,
  isStopLate,
  prettyDuration,
  getFirstLateStopWithLabel,
  getOperatorLatenessLabel,
}
